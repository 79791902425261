'use client'
import { useWindowSize } from '@/hooks/useWindowSize'
import AnchorButton from '../Atoms/AnchorButton'
import AnimatedText from '../Atoms/AnimatedText'

export default function MainBannerText() {
  const { width } = useWindowSize()
  return (
    <div className="md:absolute md:bottom-12 md:left-10 lg:left-20 z-30 mt-8 md:mt-0 px-6 xs:px-[45px] md:px-0">
      <p className="font-bold text-[14px] md:text-[20px] leading-[1.7] md:leading-none text-black md:text-white mb-3 md:mb-3">
        <AnimatedText
          txt_name="あらゆる人の心に火を灯せ"
          color={width < 769 ? 'blackMove' : 'whiteMove'}
        />
      </p>
      <h3 className="font-avenirNextBold text-[30px] xs:text-[36px] md:text-[60px] lg:text-[71px] leading-[1.1] text-black md:text-white mb-3 md:mb-4 transition-all tracking-[2.1px]">
        <AnimatedText
          txt_name="WE IGNITE <br/>YOUR HEART."
          color={width < 769 ? 'blackMove' : 'whiteMove'}
        />
      </h3>
      <div>
        <AnchorButton
          href="/jobs"
          type="black"
          size="px-4 md:px-6 py-3 md:py-4"
        >
          <span className="flex items-center">
            <span className="material-symbols-outlined mr-2">
              arrow_right_alt
            </span>
            募集職種一覧
          </span>
        </AnchorButton>
      </div>
    </div>
  )
}

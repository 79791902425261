'use client'
import SliderCardSession from "./SliderCardSession"
import ProjectStory from "./ProjectStory"
import TalkSession from "./TalkSession"
import Container from "@/components/Molecules/Container"
import { gql } from "@apollo/client"
import { useSuspenseQuery } from "@apollo/client"
import { Information } from "@/types/graphql"

export default function Interview() {

  const query = gql`
  query {
    slider: getInformationByCategorySlug(
      categorySlug: ["recruit_interview_pickup"]
      page: 1
      limit: 10
    ) {
      collection {
        id
        title
        imageFileName
        slug
        count
        createdAt
        updatedAt
        categories {
          id
          name
          slug
        }
        informationTags {
          id
          name
          slug
        }
        informationMeta {
          id
          name
          title
        }
        informationInformationMeta {
          informationMetumId
          value
        }
      }
      pageInfo {
        isFirstPage
        isLastPage
        totalPages
      }
    }
    story: getInformationByCategorySlug(
      categorySlug: ["recruit_interview_project"]
      page: 1
      limit: 3
    ) {
      collection {
        id
        title
        imageFileName
        slug
        count
        createdAt
        updatedAt
        categories {
          id
          name
          slug
        }
        informationTags {
          id
          name
          slug
        }
        informationMeta {
          id
          name
          title
        }
        informationInformationMeta {
          informationMetumId
          value
        }
      }
      pageInfo{
        isFirstPage
        isLastPage
        totalPages
      }
    }
    talk:getInformationByCategorySlug(categorySlug: ["recruit"], page: 1, limit: 2) {
      collection {
        id
        title
        imageFileName
        slug
        count
        createdAt
        updatedAt
        categories {
          id
          name
          slug
        }
        informationTags {
          id
          name
          slug
        }
        informationMeta {
          id
          name
          title
        }
        informationInformationMeta {
          informationMetumId
          value
        }
      }
      pageInfo{
        isFirstPage
        isLastPage
        totalPages
      }
    }
  }
  `


  const { data } = useSuspenseQuery<{
    slider: { collection: Information[] },
    story: { collection: Information[] },
    talk: { collection: Information[] }
  }>(query)

  return (
    <>
    {data.slider.collection && (
    <div className="mt-16 md:mt-32 overflow-hidden">
        <SliderCardSession informations={data.slider.collection}/>
    </div>
    )}
    {data.story.collection && (
    <div className="pt-20 md:pt-32 pb-16 md:pb-20 overflow-hidden">
      <div className="max-w-[1168px] mx-auto pl-6 pr-0 md:px-6">
        <ProjectStory informations={data.story.collection}/>
      </div>
    </div>
    )}
    <Container size="sm">
      <hr className="border-dashed" />
    </Container>
    {data.talk.collection && (
    <div className="pt-16 md:pt-20 pb-20 md:pb-32">
      <Container size="sm">
        <TalkSession informations={data.talk.collection}/>
      </Container>
    </div>
    )}
    </>
  )
}
'use client'
import AnimatedText from '@/components/Atoms/AnimatedText'
import Cursor from '@/components/Atoms/Cursor'
import Gradients from '@/components/Atoms/Gradients'
import VoiceCard from '@/components/Molecules/VoiceCard'
import { OUR_VOICE_IGNITE } from '@/constants/data'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'
import { LazyMotion, domAnimation, easeInOut, m } from 'framer-motion'
import Image from 'next/image'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
export default function OurVoiceIgnite() {
  const { width } = useWindowSize()
  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()
  return (
    <LazyMotion features={domAnimation}>
      <div className="oveflow-hidden relative pt-14 md:pt-36 pb-10 md:pb-20 h-[480px] md:h-[820px]">
        {/* <div className="w-full h-[500px] md:h-[640px] absolute -bottom-10 left-0 z-10 bg-repeat-x bg-top bg-[url('/images/noise_bg.webp')]" /> */}
        <div className="w-full h-full [clip-path:polygon(0_0,100%_6%,100%_100%,0_100%,0_20%)] md:[clip-path:polygon(27%_0,100%_15%,100%_100%,0_100%,0_20%)] absolute bottom-0 left-0 gradient-bg overflow-hidden">
          <Gradients id="our-gradient" style="our-ignite" />
        </div>
        <h2 className="relative uppercase font-avenirNextBold text-[28px] md:text-[56px] text-center leading-none tracking-[1.12px] md:tracking-[3.36px]">
          <AnimatedText txt_name="Our Voice" color="clip" />
          <Image
            src="/images/voice_ttl.webp"
            alt=""
            width={211}
            height={37}
            className="w-[116px] md:w-[212px] absolute -bottom-6 md:-bottom-11 -ml-1 md:-ml-1 left-1/2 -translate-x-1/2 z-20"
          />
        </h2>
        <div
          className="mt-4 md:mt-14 w-full relative z-20"
          onMouseEnter={() => {
            setMouseProperties({
              text: 'DRAG',
              color: '#ffffff',
              background: '#242A32',
            })
            setMouseDisplay(true)
          }}
          onMouseLeave={() => setMouseDisplay(false)}
        >
          <Swiper
            loop
            centeredSlides
            centerInsufficientSlides
            centeredSlidesBounds
            slidesPerView={'auto'}
            pagination={false}
            modules={[Autoplay]}
            grabCursor
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
              reverseDirection: false,
            }}
            className="voice-swiper"
          >
            {OUR_VOICE_IGNITE.map((item, index) => (
              <SwiperSlide
                key={`${item.text}-${index}`}
                className="!w-[231px] sm:!w-[250px] md:!w-[352px]"
              >
                {({ isActive }) => (
                  <m.div
                    initial={{ scale: 1, y: 0 }}
                    animate={{
                      scale: isActive ? 1 : 0.85,
                      y: !isActive ? (width > 768 ? 22 : 16) : 0,
                    }}
                    transition={{ duration: 0.3, ease: easeInOut }}
                  >
                    <VoiceCard
                      text={item.text}
                      tag={item.tag}
                      image={item.image}
                      active={isActive}
                    />
                  </m.div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {width > 768 && (
          <Cursor
            textMouse={mouseProperties.text}
            textColor={mouseProperties.color}
            background={mouseProperties.background}
            display={mouseDisplay}
            mixblendMode="mix-blend-hard-light"
          />
        )}
        <div className="flex justify-center mt-3 md:mt-12 relative z-20">
          <div className="flex justify-center flex-col items-end">
            <span className="font-bold text-[16px] md:text-[24px] leading-[1.7] inline-block relative">
              <AnimatedText
                txt_name="# あなたの心に火が灯った瞬間は？"
                color="clip"
              />
            </span>
            <Image
              src="/images/voice_line.svg"
              alt=""
              width={256}
              height={5}
              className="w-[165px] md:w-[256px] h-auto relative z-10"
            />
          </div>
        </div>
      </div>
    </LazyMotion>
  )
}

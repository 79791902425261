import Image from 'next/image'

export default function VoiceCard({
  text,
  tag,
  image,
  active,
}: {
  text: string
  tag: string
  image: string
  active: boolean
}) {
  return (
    <div className="relative pb-8 md:pb-0 pt-6">
      <div className="w-[40px] md:w-[65px] h-auto absolute top-1 right-6 md:right-8 z-[2] [&>svg]:w-full">
        <svg
          width="66"
          height="40"
          viewBox="0 0 66 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3727 0.36829C15.8816 -0.122763 15.0855 -0.122764 14.5944 0.36829L0.368291 14.5944C-0.122763 15.0854 -0.122764 15.8816 0.368291 16.3726L10.7043 26.7086L5.09653 38.2217L6.87479 40L16.0346 30.8402C16.1567 30.7807 16.2712 30.7002 16.3727 30.5987L30.5988 16.3726C31.0899 15.8816 31.0899 15.0854 30.5988 14.5944L16.3727 0.36829Z"
            fill={`${active ? '#242A32' : '#AAB4C0'}`}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.2418 0.36829C50.7508 -0.122763 49.9546 -0.122764 49.4636 0.36829L35.2374 14.5944C34.7464 15.0854 34.7464 15.8816 35.2374 16.3726L45.5733 26.7085L39.9655 38.2217L41.7438 39.9999L50.9033 30.8404C51.0256 30.7809 51.1402 30.7003 51.2418 30.5987L65.4679 16.3726C65.959 15.8816 65.959 15.0854 65.4679 14.5944L51.2418 0.36829Z"
            fill={`${active ? '#242A32' : '#AAB4C0'}`}
          />
        </svg>
      </div>
      <div className="bg-white h-[240px] md:h-[348px] px-6 md:px-10 pt-6 md:pt-10 pb-14 md:pb-32 rounded-[32px] relative">
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          className={`text-[12px] md:text-[16px] leading-[1.7] font-medium ${
            active ? 'text-darkgrey_900' : 'text-darkgrey_200'
          }`}
        />
        <p className="mt-2">
          <span className="text-[11px] md:text-[12px] leading-none text-khaki_400">
            {tag}
          </span>
        </p>
        <Image
          src={image}
          alt=""
          width={90}
          height={90}
          className="w-[76px] md:w-[90px] h-auto absolute -bottom-8 md:bottom-6 left-1/2 -translate-x-1/2 z-[3]"
        />
        {/* Triangle  */}
        <Image
          src="/images/triangle_white.webp"
          alt=""
          width={54}
          height={22}
          className="w-[54px] h-auto absolute bottom-0 -right-1"
        />
      </div>
    </div>
  )
}

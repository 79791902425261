'use client'
import ArrowCircle from '@/components/Atoms/ArrowCircle'
import Cursor from '@/components/Atoms/Cursor'
import TitleJp from '@/components/Atoms/TitleJp'
import AboutCard from '@/components/Molecules/AboutCart'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'
import Link from 'next/link'

const data = [
  {
    textJp: '私たちの価値観',
    textEn: 'Values',
    imagePc: '/images/top/about_01.webp',
    imageSp: '/images/top/about_01_sp.webp',
    href: '/value',
  },
  {
    textJp: '創業者メッセージ',
    textEn: 'Message',
    imagePc: '/images/top/about_02.webp',
    imageSp: '/images/top/about_02_sp.webp',
    href: '/message',
  },
]
const aboutNav = [
  {
    tilte: '福利厚生',
    href: '/benefits',
  },
  {
    tilte: '評価制度・キャリア支援',
    href: '/carrier',
  },
  {
    tilte: '働く環境',
    href: '/workstyle',
  },
]
export default function AboutUs() {
  const { width } = useWindowSize()
  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()
  return (
    <div className="md:max-w-[1120px] mx-auto">
      <TitleJp text="私たちについて" subText="ABOUT US" />
      <div className="md:flex justify-between mt-8 md:mt-12 relative">
        {data.map((item) => (
          <div
            key={item.textJp}
            className="mb-5 last:mb-0 md:w-[calc(50%_-_16px)]"
            onMouseEnter={() => {
              setMouseProperties({
                text: ' CLICK',
                color: '#ffffff',
                background: '#242A32',
              })
              setMouseDisplay(true)
            }}
            onMouseLeave={() => setMouseDisplay(false)}
          >
            <AboutCard
              href={item.href}
              imagePc={item.imagePc}
              imageSp={item.imageSp}
              textEn={item.textEn}
              textJp={item.textJp}
            />
          </div>
        ))}
      </div>
      {width > 768 && (
        <Cursor
          textMouse={mouseProperties.text}
          textColor={mouseProperties.color}
          background={mouseProperties.background}
          display={mouseDisplay}
          mixblendMode="mix-blend-hard-light"
        />
      )}
      {/* List link  */}
      <div className="md:flex items-center border-y border-darkgrey_100 md:py-5 mt-10">
        {aboutNav.map((item, index) => (
          <Link
            key={`${item}-${index}`}
            href={`${item.href}`}
            className="flex-1 flex items-center justify-between px-4 md:px-6 md:border-r md:last:border-r-0 border-t md:border-t-0 first:border-t-0 border-darkgrey_100 md:border-dashed py-4 md:py-0 group"
          >
            <span className="text-[14px] md:text-[16px] leading-none font-bold group-hover:text-blue_400 transition-colors duration-150">
              {item.tilte}
            </span>
            <ArrowCircle size="lg" />
          </Link>
        ))}
      </div>
    </div>
  )
}

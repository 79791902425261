'use client'
import { useWindowSize } from '@/hooks/useWindowSize'
import Video from '../Atoms/Video'
export default function MainBannerVideo() {
  const { width } = useWindowSize()
  return (
    <>
      {width > 768 && (
        <Video
          src_webm="/videos/fv_pc.webm"
          src_mp4="/videos/fv_pc.mp4"
          style="hidden md:block w-full h-full object-cover"
          id="main-video-pc"
          poster="/images/top/fv_pc.webp"
          preload
        />
      )}
      {width < 769 && width != 0 && (
        <Video
          src_webm="/videos/fv_sp.webm"
          src_mp4="/videos/fv_sp.mp4"
          style="md:hidden w-full h-full object-cover"
          id="main-video-sp"
          poster="/images/top/fv_sp.webp"
          preload
        />
      )}
    </>
  )
}

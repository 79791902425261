'use client'
import Gradients from '@/components/Atoms/Gradients'
import SliderCard from '@/components/Molecules/SliderCard'
import { Information } from '@/types/graphql'

export default function SliderCardSession(props:{informations:Information[]}) {
const informations= props.informations
  return (
    <div className="relative pt-[130px] md:pt-[180px] pb-10 md:pb-20 md:h-[600px] lg:h-[800px]">
      {/* <div className="w-full h-[500px] md:h-[640px] absolute -bottom-10 left-0 z-10 bg-repeat-x bg-bottom bg-[url('/images/noise_bg.webp')]" /> */}
      <div className="w-full h-full [clip-path:polygon(0_0,100%_6%,100%_100%,0_100%,0_20%)] md:[clip-path:polygon(27%_0,100%_15%,100%_100%,0_100%,0_20%)] absolute top-4 md:top-0 -bottom-8 md:bottom-0 left-0 gradient-bg">
        <Gradients id="card-gradient" style="card-gradient" />
      </div>
      <SliderCard informations={informations} />
    </div>
  )
}

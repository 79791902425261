import Link from 'next/link'
import Picture from '../Atoms/Picture'

type AboutCardT = {
  imagePc: string
  imageSp: string
  textJp: string
  textEn: string
  href: string
}
export default function AboutCard({
  imagePc,
  imageSp,
  textJp,
  textEn,
  href,
}: AboutCardT) {
  return (
    <div
      className={`rounded-[3px] relative overflow-hidden group transition duration-500 hover:shadow-[0_0_40px_-10px_rgba(92,111,131,0.4)]`}
    >
      <Link
        href={href}
        className="absolute top-0 left-0 w-full h-full block z-30"
      />
      <Picture
        pcSrc={imagePc}
        spSrc={imageSp}
        screenSize="750"
        style="w-full h-auto transition duration-500 group-hover:saturate-[120%] group-hover:brightness-95"
      />
      <div className="absolute left-4 md:left-8 bottom-4 md:bottom-6 text-white leading-none z-20">
        <p className="font-bold  mb-3 text-[18px] md:text-[24px]">{textJp}</p>
        <p className="font-avenirNextBold text-[11px] md:text-[14px] tracking-wider uppercase">
          {textEn}
        </p>
      </div>
    </div>
  )
}

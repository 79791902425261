import Link from 'next/link'

export default function Tag({
  href,
  type,
  mouseEnter,
  name,
}: {
  href: string
  type: 'button' | 'text'
  mouseEnter?: () => void
  name: string
}) {
  return {
    ...(href ? (
      <Link
        href={`/interview/category/${href}`}
        className={`text-[11px] md:text-[12px] leading-[2] text-khaki_400 mr-1 my-1 font-medium ${type == 'button' && 'text-center inline-block text-khaki_400 bg-black_50 rounded-[2px] px-[6px]'} relative z-[2] hover:text-blue_400 transition-colors duration-300`}
        onMouseEnter={mouseEnter}
      >
        {name}
      </Link>
    ) : (
      <div
        className={`text-[11px] md:text-[12px] leading-[2] text-khaki_400 mx-1 my-1 font-medium ${type == 'button' && 'text-center inline-block text-khaki_400 bg-black_50 rounded-[2px] px-[6px]'} relative z-[2]`}
        onMouseEnter={mouseEnter}
      >
        {name}
      </div>
    )),
  }
}

import Image from 'next/image'
import Link from 'next/link'
import Tag from '../Atoms/Tag'
import { InformationCategory } from '@/types'

export default function TalkCard({
  title,
  categories,
  tags,
  images,
  href,
  onEnter,
  onLeave,
  size,
}: {
  title: string
  categories:  any
  tags: { id?: string; name: string; slug: string; tag?: boolean }[]
  images: string
  href: string
  onEnter?: () => void
  onLeave?: () => void
  size?: 'sm' | 'lg'
}) {
  return (
    <div className="relative group">
      <Link
        href={`${href}`}
        className="absolute left-0 top-0 w-full h-full block z-[1]"
      />
      <div className="flex items-center md:block">
        <div className="relative w-1/2 md:w-full z-[2]">
          <Link
            href={`${href}`}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            className="relative overflow-hidden rounded-[3px] md:rounded-md block transition duration-500 group-hover:shadow-[0_0_40px_-10px_rgba(92,111,131,0.4)]"
          >
            <Image
              alt=""
              src={images}
              width={700}
              height={300}
              className="w-full h-auto transition duration-500 group-hover:saturate-[120%] group-hover:brightness-95"
            />
          </Link>
        </div>
        <p
          className={`font-bold md:h-[82px] text-[14px] leading-[1.7] line-clamp-3 flex-1 pl-4 md:pl-0 ${size == 'sm' ? 'md:text-[16px] md:mt-4' : 'md:text-[18px] md:mt-6'}`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div
        className={`flex items-center flex-wrap mt-4 md:mt-2 ${size == 'sm' && 'md:mt-4'}`}
      >
        {categories.map((item:InformationCategory, index:number) => (
          <>
            {item?.name !== 'ピックアップ' &&
              item?.name !== 'リクルートサイト' && (
                <Tag
                  key={item.name + index}
                  href={item.slug}
                  type="button"
                  name={item.name}
                />
              )}
          </>
        ))}
        {tags.map((item, index) => (
          <Tag
            key={item.name + index}
            href={''}
            type="text"
            name={'#' + item.name}
          />
        ))}
      </div>
    </div>
  )
}

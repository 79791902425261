'use client'
import { useInView } from 'framer-motion'
import { useRef, useEffect } from 'react'
const userAgent =
  typeof window !== 'undefined' ? window.navigator.userAgent : ''
const safariPattern = /Safari\//i
const chromePattern = /Chrome\//i
const isSafari = safariPattern.test(userAgent) && !chromePattern.test(userAgent)
export default function Video({
  src_webm,
  src_mp4,
  poster,
  style,
  id,
  preload,
}: {
  src_webm: string
  src_mp4: string
  poster?: string
  style?: string
  id: string
  preload: boolean
}) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const isInView = useInView(videoRef, { once: true })
  useEffect(() => {
    if (isInView && videoRef.current) {
      videoRef.current
        .play()
        .catch((error) => console.log('Playback failed', error))
    }
  }, [isInView])

  return (
    <video
      id={id}
      preload={preload ? 'auto' : 'none'}
      muted
      loop
      width="100%"
      ref={videoRef}
      playsInline
      poster={poster}
      className={`${style}`}
    >
      {!isSafari && <source src={src_webm} type="video/webm" />}
      <source src={src_mp4} type="video/mp4" />
    </video>
  )
}

'use client'
import Cursor from '@/components/Atoms/Cursor'
import TitleEn from '@/components/Atoms/TitleEn'
import More from '@/components/Molecules/More'
import TalkCard from '@/components/Molecules/TalkCard'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Information } from '@/types/graphql'

export default function TalkSession( props:{ informations : Information[] }) {
  const informations = props.informations

  const { width } = useWindowSize()
  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()
  return (
    <div className="relative">
      <TitleEn text="Latest Interview" uppercase />
      {informations && (
        <div className="md:flex justify-between md:[&>*]:w-[calc(100%_/_2_-_16px)] mt-8 md:mt-10">
          {informations.map((item, index) => (
            <div className="mb-8 md:mb-0" key={`talk-${item}-${index}`}>
              {item.title && item.categories && item.informationTags && (
                <TalkCard
                  title={item.title}
                  categories={item.categories}
                  tags={item.informationTags}
                  images={
                    process.env.NEXT_PUBLIC_AWS_S3_URL +
                    '/' +
                    item.id +
                    '/original_' +
                    item.imageFileName
                  }
                  href={'/interview/' + item.slug}
                  onEnter={() => {
                    setMouseProperties({
                      text: ' CLICK',
                      color: '#ffffff',
                      background: '#242A32',
                    })
                    setMouseDisplay(true)
                  }}
                  onLeave={() => setMouseDisplay(false)}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {width > 768 && (
        <Cursor
          textMouse={mouseProperties.text}
          textColor={mouseProperties.color}
          background={mouseProperties.background}
          display={mouseDisplay}
          mixblendMode="mix-blend-hard-light"
        />
      )}
      <div className="md:absolute right-0 top-0 mt-8 md:mt-0">
        <More href="/interview/all" text="View more" type="text" />
      </div>
    </div>
  )
}

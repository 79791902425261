'use client'
import Image from 'next/image'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
export default function SliderLoop({ data }: { data: string[] }) {
  const breakpoints = {
    769: {
      spaceBetween: 16,
      speed: 18000,
    },
    320: {
      spaceBetween: 6,
      speed: 12000,
    },
  }

  return (
    <div>
      <Swiper
        slidesPerView={'auto'}
        pagination={false}
        modules={[Autoplay]}
        breakpoints={breakpoints}
        allowTouchMove={false}
        autoplay={{ delay: 0, disableOnInteraction: false }}
        className="top-slide"
        loop
        centerInsufficientSlides
      >
        {data.map((item, index) => (
          <SwiperSlide
            className="!max-w-[235px] md:!max-w-[625px]"
            key={`${index}-slider`}
          >
            <div className="rounded-[3px] md:rounded-[6px] overflow-hidden">
              <Image
                src={item}
                alt=""
                width={625}
                height={280}
                className="w-full h-auto"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
